/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {
    .portfolio .col4 .grid-item {
    width: 24.9%;
    margin: 0;
    padding: 0;
}
    .contact-area .map {
    position: absolute;
    right: -300px;
    top: 0;
}
.container-p {
	padding: 0 30px;
}
.header-btn {
	margin-left: 20px;
}
.slider-active .slick-arrow {
	left: 20px;
}
.slider-active .slick-next {
	right: 20px;
	left: auto;
}
.section-t h2 {
	font-size: 250px;
}
.f-cta-area {
	margin-left: 60px;
	margin-right: 60px;
	padding-left: 50px;
	padding-right: 50px;
}
.main-menu ul li:first-child{margin-left: 0;}
.header-social.display-ib {
	display: none;
}
.wp-img {
	margin-right: 0;
}
.contact-img {
	right: 0px;
}
.menu-area::before {
    width: 26%;
}
    .single-project .project-info {
    right: 10px;
}
}


/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {
    .f-contact li {
    display: flex;
}
    .slider-content h2 {
    font-size: 48px;
}
    .pricing-head h4 {
    font-size: 24px;
}
    .blog-thumb .b-meta {
    font-size: 12px;
}
    .f-contact span {
    width: 77%;
}
.contact-area .map {
    right: -500px;

}
    .team-active .slick-arrow {
    left: 0;
}
    .team-active .slick-next {
    left: inherit;
    right: 0;
}
    .button-group button {
    margin: 0 6px;
}
    .services-active2 .slider-nav .slick-list.draggable {
    width: 920px;
    float: right;
}
    .services-item{
        width: 275px;
    }
    .services-active2 .slick-arrow {
    left: 0px;

}
    .services-active2 .slick-next {
    left: inherit;
    right: 0px;
}
 .right-menu .text strong {
    color: #002e5b;
    font-size: 14px;
}
    #project {
    background-size: initial !important;
}
   #project .slick-dots {
    display: none !important;
}
    .portfolio-active .slick-next {
    left: 75.5%;
}
 
    .second-menu .main-menu ul li {
    margin-left: 15px;
}
.top-btn {
   font-size: 11px;
padding: 16px 27px;
}
    .header-cta ul li{
    font-size: 12px;
}
.menu-area::before {
    width: 27%;
    top: 48px;
}
    .sticky-menu::before {
    height: 71px;
    top: 0;
    left: -20px;
}
.call-area h2 {
    color: #fff;
    margin-top: 100px;
    font-size: 26px;
}
  .call-area .number {
    font-size: 40px;
}
.container-p {
	padding: 0 30px;
}
.header-cta ul li {
	margin-left: 20px;
}
.section-t h2 {
	font-size: 200px;
}
.about-img {
	margin-left: -105px;
}
.features-content p {
	padding: 0;
}
.services-content {
	padding: 50px 30px;
	padding-bottom: 45px;
}
.services-content h4 {
	font-size: 20px;
}
.services-content span {
	margin-bottom: 45px;
}
.video-wrap img {
	width: 100%;
}
.video-position {
    bottom: -222px;
    left: 20px;
    right: 20px;
}
.v-padding {
	padding-top: 0;
}
.wp-list {
	padding: 35px 15px;
	padding-bottom: 25px;
}
.wp-img {
	margin-right: -100px;
}
.wp-tag {
	left: 10px;
}
.team-info {
	margin-left: 0;
	margin-right: 0;
}
.team-info h4 {
	font-size: 18px;
}
.single-counter p {
	font-size: 18px;
}
.ta-bg {
	margin-left: -25px;
}
.b-meta.mb-20 {
	margin-bottom: 10px;
}
.b-meta ul li {
	margin-right: 0;
}
.blog-content {
	padding: 30px 20px;
}
.blog-content h4 {
	font-size: 18px;
	margin-bottom: 20px;
}
.blog-content p {
	margin-bottom: 20px;
}
.f-cta-area {
	margin-left: 20px;
    margin-right: 20px;
    padding-left: 50px;
	padding-right: 50px;
}
.footer-social span {
	margin-right: 12px;
}
.f-insta ul li {
	width: 28%;
}
.f-insta ul li a img {
	width: 100%;
}
.wp-bg {
	overflow: hidden;
}

.about-p {
	padding-top: 110px;
}
.s-single-services {
	padding: 50px 30px;
}
.fshape-one {
	top: 30%;
}
.fshape-two {
	left: 9%;
}
.chosse-img {
	width: 45%;
}
.choose-wrap.pl-100 {
	padding-left: 50px;
}
.choose-content p {
	padding-right: 50px;
}
.inner-wp-icon {
	display: none;
}
.wp-thumb.mb-35 {
	margin-bottom: 25px;
}

.contact-img {
	right: -150px;
}
.bsingle__content h2 {
	padding-right: 0;
	font-size: 27px;
}
.widget__banner-overly > span {
	margin-bottom: 120px;
}
.widget__banner-overly h3 {
	margin-bottom: 91px;
	font-size: 35px;
}
.widget__post-content h6 {
	font-size: 14px;
	padding-right: 0;
}
.details__content h2 {
	font-size: 26px;
}
.related-post-wrap .rp__content {
	padding: 23px;
}
.related-post-wrap .rp__content h3 {
	font-size: 20px;
}

}


/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
    .slider-four .subricbe {
    width: 100%;
}
    .featured-courses-active {
    margin: 0;
}
      .button-group {
    display: flex;
    overflow: scroll;
}
    .button-group button {
    margin: 0 4px;
    flex: 0 0 33%;
}
    .portfolio .col4 .grid-item {
    width: 50%;
}
     .header-two .logo {
    padding: 15px 0;
}
     .header-two   .mean-container a.meanmenu-reveal {
    margin-top: -56px;
}
    .slider-active .slick-track::before {
    background: #fff5f4;
}
    
    .s-about-img.p-relative {
    margin-bottom: 50px;
}
    .slider-content {
    margin-top: 100px;
}
    .mean-container a.meanmenu-reveal {
    margin-top: -47px;
}
    .pricing-box {
    padding: 0;
margin-bottom: 90px;
}
    .contact-bg02 {
    padding: 30px;
    margin-bottom: 80px;
}
    .contact-bg-an-02 {
    right: -100px;
    bottom: 0;
}
    .testimonial-area .test-an-02{
        display: none;
    }
    .testimonial-area .test-an-01 {

    bottom: -150px;
}
    #mobile-menu {
    background: #002e5b;
}
    .header-cta ul li {
    display: inline-block;
    margin-left: 12px;
}
    .contact-area .map {
      display: none;
}
    .number {
    font-size: 48px;
}
    .services-active2 .slider-nav {
    top: 200px;
}
    .services-active2 .slider-nav .slick-list.draggable {
    width: 650px;
}
    .newslater-area #contact-form4 {
    margin-top: 0;
    float: right;
    width: 100%;
    padding-left: 0;
}
    
.cta-area.cta-bg::before {
    height: 100%;
    width: 100%;
    top: 0;

}
    .cta-bg{
        padding: 100px 0;
    }

    .cta-bg .video-img {
    margin-top: 0px;
    height: 757px;
}
    .services-active2 .slick-arrow{
        left: 0;
    }
    .services-active2 .slick-next {
    left: inherit;
    right: 0px;
}
 .menu-area::before {
    width: 32%;
    height: 84px;
    left: -34px;
}
    .sticky-menu::before {
    height: 84px;
    top: 0;
    left: -29px;
}
    .logo {
    height: auto;
    margin-top: 0;
    line-height: inherit;
    width: 25%;
}
    .main-menu .sub > ul{
        position: relative;
    }
    
.container-p {
	padding: 0 30px;
}

.slider-bg {
	min-height: 700px;
}
.section-t h2 {
	font-size: 150px;
}
.about-img {
	margin-left: 0;
	text-align: center;
	margin-bottom: 30px;
}
.about-content.pl-30 {
	padding-left: 0;
	padding-bottom: 4px;
}
.features-content p {
	padding: 0;
}
.section-title h2 {
	font-size: 52px;
}
.services-content {
	padding: 50px 28px;
	padding-bottom: 45px;
}
.video-position {
	bottom: 70px;
	left: 120px;
}
.wp-img {
	margin-top: 30px;
	margin-bottom: -15px;
	margin-right: 0;
}
.counter small {
	top: -20px;
}
.cta-right {
	margin-top: 35px;
}
.ta-bg {
	background-position: center;
	margin: 25px auto;
}
.testimonial-area.pb-120 {
	padding-bottom: 185px;
}
.f-cta-area {
	margin-left: 20px;
    margin-right: 20px;
    padding-left: 50px;
	padding-right: 50px;
}
.menu-area {
	padding: 10px 0;
}

.responsive {
	float: right;
	display: block;
	cursor: pointer;
	margin-top: -38px;
}
.responsive i {
    color: #002e5b;
    font-size: 25px;
}
#mobile-menu {
	display: none;
	padding: 20px;
}
.main-menu {
	text-align: left !important;
}
.main-menu ul li {
	display: block;
	border-bottom: 1px solid #f7f7f74f;
	margin-left: 0;
}
.main-menu ul li.active a, .main-menu ul li:hover a {
	color: #000;
}
.main-menu  ul > li > a::before {content: none;}
.main-menu ul li a {
	color: #fff;
	padding: 12px 20px;
	display: block;
	font-size: 14px;
	    font-family: 'Jost', sans-serif;
	font-weight: 500;
}
.second-menu {
	margin-bottom: 0;
	box-shadow: none;
	padding: 0;
	margin-top: 0;
}
.second-menu::before {
	content: none;
}
.second-header {
	padding: 10px 0;
}
.second-menu .main-menu ul li {
	margin-left: 0;
}

.s-about-img img {
	width: 100%;
}
.s-video-content {
	padding-left: 100px;
}
.chosse-img {
	display: none;
}
.testimonial-p {
	padding-top: 100px;
	padding-bottom: 100px;
}
.contact-img {
	display: none;
}
.contact-bg.pb-80 {
	padding-bottom: 120px;
}
.single-post {
	box-shadow: 0px 10px 80px 0px rgba(193, 193, 193, 0.41);
}
.breadcrumb-area {
	min-height: 200px;
}
.bsingle__content h2 {
	font-size: 28px;
}
.widget__banner-overly > span {
	margin-bottom: 320px;
}
.widget__banner-overly h3 {
	margin-bottom: 352px;
	font-size: 80px;
}
.widget__banner-overly h3 span {
	font-size: 61px;
}
.details__content h2 {
	font-size: 29px;
}
.avatar__wrap {
	padding: 50px 65px;
}
.b-details-p.pt-120 {
	padding-top: 115px;
}
.portfolio .col3 .grid-item, .portfolio .col2 .grid-item {
    width: 33.33%;
    padding: 15px;
}
.gallery-image img {
    width: 100%;
}
}


/* small mobile :320px. */
@media (max-width: 767px) {
    .course-meta.review.style2 li::after {
    right: 10px;
}
    .course-meta.review.style2 li {
      padding-right: 19px;
}
    .couress-box .course-meta.desc li::after{
        right: -14px;
    }
    .couress-box .course-meta li{
        margin-right: 24px;
    }
    .f-contact span {
    width: 122%;
}
    .box-courses img{
        width: 100%;
    }
    .footer-widget.footer-social.mt-15.text-right.text-xl-right {
    text-align: left !important;
}
    .footer-widget.footer-link.mt-20.text-center {
    text-align: left !important;
    margin-bottom: 30px;
}
    .blog-content2 .b-meta .date-b {
    width: 80%;
}
    .testimonial-active .ta-info {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 15px;
        width: 100%;
}
     .featured-courses-active {
    margin: 0;
}
    .eventes-box {
    display: inline-block;
    margin-bottom: 30px;
}
    .eventes-box .date-box {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
}
    .eventes-box .date-box::before {
    width: 89%;
}
    .subricbe .contact-form {
    display: initial;
}
    .slider-four .subricbe{
        width: auto;
    }
    .subricbe .contact-form label {
    width: 100%;
    border-right: none;
    margin-bottom: 0;

}
    .slider-btn .video-i{
        display: none;
    }
    .slider-three .slider-img {
    margin-left: 0;
    margin-top: 50px;
    margin-right: 0;
}
    .slider-content.s-slider-content.text3 {
    margin-top: 0;
}
    .button-group {
    display: flex;
    overflow: scroll;
}
    .button-group button {
    margin: 0 4px;
    flex: 0 0 55%;
}
    .portfolio .col4 .grid-item {
    width: 100%;
}
    .project-detail .pr-ul li {
    display: flex;
    margin-bottom: 15px;
    float: left;
    width: 100%;
}
         .header-two .logo {
    padding: 15px 0;
}
     .header-two   .mean-container a.meanmenu-reveal {
    margin-top: -56px;
}
    
    .adim-box{
        margin-bottom: 30px;
    }
       
   
    .header-btn {
    position: relative;
    right: 0;
    top: 0;
    bottom: 0;
    color: #fff;
    margin-left: 0;
    width: 100%;
}
    .s-about-img.p-relative {
    margin-bottom: 50px;
}
    .slider-content {
    margin-top: 100px;
}
    .mean-container a.meanmenu-reveal {
    margin-top: -47px;
}
  
    .pricing-box {
    padding: 0;
margin-bottom: 90px;
}
    .contact-bg02 {
    padding: 30px;
    margin-bottom: 80px;
}
    .contact-bg-an-02 {
    right: -100px;
    bottom: 0;
}
    .testimonial-area .test-an-02{
        display: none;
    }
    .testimonial-area .test-an-01 {

    bottom: -150px;
}
    .slider-bg .text2 h2 {
    font-size: 38px;
}
    .slider-four .slider-img {
    margin-left: 0;
    margin-top: 50px;
    margin-right: 0;
}
    .slider-active .slick-track::before {
    background: #fff5f4;
}
    
    #feature-top {
    margin-top: -40px;
  }
    .cta-area{
        padding: 100px 0;
    }
    .cta-area.cta-bg::before {
    height: 100%;
     top: 0;
}

      .team-active .slick-arrow {
    left: 0px;
}
    .team-active .slick-next {
    left: inherit;
    right: 0px;
}
  
    .faq-wrap {
    margin-top: 50px;
}

    .contact-area .map {
     display: none;
}
    .single-testimonial-bg {
    border-radius: 10px;
    width: 100%;
    float: right;
    padding: 300px 50px 100px 50px;
    margin: 0;
}
    .testimonial-active .slick-dots {
    width: 100%;
    bottom: 7%;
}
    .testimonial-active .ta-info h6 {
    font-size: 18px;
}
    .services-active2 .slider-nav .slick-list.draggable {
    width: 300px;
    float: right;
}
    .services-item {
    width: 280px;
}
    .services-active2 .slider-nav {
    position: absolute;
    top: 150px;
}   
    .services-active2 .slick-arrow {
    left: -15px;
    }
    .services-active2 .slick-next {
    left: inherit;
    right: -15px;
}
    .services-active2 h2{
        font-size: 28px;
        margin-top: 30px;
    }
    .sinature-box {
    position: relative;
    left: 0;
    width: 100%;
}
    .newslater-area #contact-form4 {
    margin-top: 0;
    float: right;
    width: 100%;
    padding-left: 0;
}
    .service-detail {
    padding-left: 0;
}
            .portfolio-active .slick-arrow {
    top: -173px;
    left: 60.5%;
}
    .portfolio-active .slick-next {
    left: 79.5%;
}
    .call-area h2 {
    margin-top: 0;
}
    .com-icon {
    top: 80px;
        width: 50px;
}
    .com-icon img{
        width: 100%;
    }
    
    .counter-area {
    background-size: cover;
    padding: 120px 0;
}
    .single-counter-img {
    margin-bottom: 50px;
}
    .single-counter {
    margin: 10px;
}
    #project {
    background-size: cover !important;
        padding-bottom: 90px;
}
    .call-area {
    background-size: cover;
    padding: 120px 0;
}
    .call-area h2 span {
    font-size: 34px;
}
    .call-area .number {
    font-size: 36px;
}
    .testimonial-active2 .ta-info {
    margin-top: 30px;
    margin-bottom: 30px;
}
    .testimonial-active2 .testi-author img {
    margin-bottom: 0;
}
    .testimonial-active2 .slick-arrow {
    display: none !important;
}
    .cta-bg .video-img {
    margin-top: 50px;
    height: 500px;
}
    .logo {
    height: auto;
    margin-top: 0;
    line-height: inherit;
    width: 50%;
}
    
	.slider-price {
    display: none;
}
	.slider-bg2 .slider-content > span {
    font-size: 18px;
}
.slider-bg2 .video-i{
	display:none;
}

.slider-bg2 {
    min-height: 700px;
    position: relative;
    margin-top: -118px;
    z-index: 1;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
	.row {
    margin-right: 0;
    margin-left: 0;
}
	.portfolio .col3 .grid-item, .portfolio .col2 .grid-item {
    width: 100%;
    padding: 15px;
}
.gallery-image img {
    width: 100%;
}

.woocommerce #content div.product .woocommerce-tabs ul.tabs li, .woocommerce div.product .woocommerce-tabs ul.tabs li, .woocommerce-page #content div.product .woocommerce-tabs ul.tabs li, .woocommerce-page div.product .woocommerce-tabs ul.tabs li {
    width: 100%;
    text-align: center;
}
	.woocommerce ul.products[class*="columns-"] li.product, .woocommerce-page ul.products[class*="columns-"] li.product {
    width: 100%;

}
.woocommerce #content div.product div.images, .woocommerce div.product div.images, .woocommerce-page #content div.product div.images, .woocommerce-page div.product div.images {
    float: left;
    width: 100%;
    overflow: hidden;
}
.has-mega-menu{
        position:relative;
    }
	 
    li.sub.has-mega-menu ul{
        position: relative;
        top: 0px; /*insert the needed value*/
        z-index: 100;
        left:0px;
        right:0px;
		width: 100%;
    }
    li.sub.has-mega-menu:hover>ul{
    /*flexbox fallback for browsers that do not support CSS GRID lyout*/
        display: flex;
        flex-wrap: wrap;
    /*CSS GRID lyout*/ 
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(100%, 1fr));   
		margin-left: 0px;
		height:200px;
		overflow:auto;
    }
	.timeline {

    padding: 50px 0px 50px 50px;
   
}	
	.tag-template img {
    width: 100% !important;
}
.quote-post .meta-info {
    padding: 10px 25px;
}
	.admin-bar .menu-area {
    margin-top: 0;
}
.admin-bar .sticky-menu {
   margin-top: 13px;
}
.tag-markup-2 li > ul, li > ol, .pages-content li > ul, li > ol {
    padding-left: .5rem;
}
	.size-full.wp-image-906 {
    width: 100%;
    height: auto;
}

	.pages-content img {
    width: 100%;
    height: auto;
}
	.row {
     margin-right: 0;
    margin-left: 0;
}
	.header-btn.second-header-btn {
    display: none;
	}

.f-cta-area {
    margin-bottom: 0;
}
.search-form label{
	width:100%;
}
	.has-3-columns .wp-block-column,.has-5-columns .wp-block-column,
	.has-6-columns .wp-block-column,.has-4-columns .wp-block-column,
	.has-2-columns .wp-block-column,.wp-block-media-text.alignwide figure,
	.wp-block-media-text__content,.wp-block-gallery.alignwide.columns-4.is-cropped li,
	.wp-block-gallery.columns-6.is-cropped li,.wp-block-gallery.columns-7.is-cropped li,
	.wp-block-gallery.columns-8.is-cropped li
	{
		width:100%;
	}
	#contact-form {
    padding: 0 15px;
}
.s-video-wrap {
    padding-left: 13px;
    padding-right: 13px;
}
	.wp-block-gallery.columns-3 li {
    width: 100%;
	}
	.wp-block-gallery li {
    width: 50%;
	}
	
.main-menu .sub > ul {
    display: none;
    position: relative;
    float: left;
    width: 100%;
    margin-bottom: 10px;

}
.main-menu .sub > ul {
    border-top:1px solid #f7f7f7;
}
.main-menu .sub > ul > .sub > ul {
    display: none;
    position: relative;
    float: left;
    width: 100%;
    margin-bottom: 10px;
	margin-left:0;
	margin-top:0;	
	padding-left:15px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0);
	border-top:1px solid #f7f7f7;

}
.main-menu .sub > ul > .sub > ul li:last-child{
	border-bottom:none;
}
.main-menu .sub > ul > .sub > ul > .sub > ul {
    display: none;
    position: relative;
    float: left;
    width: 100%;
    margin-bottom: 10px;
	margin-left:0;
	margin-top:0;
	background:#d29751;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0);

}
.main-menu .sub li a{
	padding-left:20px;
}
.menu-item ul.sub-menu .sub-menu li,.menu-item ul.sub-menu .sub-menu .sub-menu li {

border-bottom: none;
}
	.comment-author-admin {
   margin-left: 0;
}
.container-p {
	padding: 0 15px;
}
.menu-area {
	padding-top: 15px;
	padding-bottom: 5px;
}
.slider-bg {
	min-height: 600px;
}
.slider-content h2 {
	font-size: 30px;
    letter-spacing: 0;
    margin-bottom: 30px;
}
.menu-area::before {
    width: 56%;
    height: 82px;
    top: 0;
    left: -40px;
}
.down-arrow {
	bottom: 20px;
}
.section-t{display: none;}
.about-img {
	margin-left: 0;
}
.about-img img {
	width: 100%;
}
.about-text span {
	top: 20px;
}
.about-text {
	height: 109px;
	width: 214px;
}
.about-content.pl-30 {
    padding-left: 0;
    margin-top: 30px;
    padding-bottom: 3px;
}
.about-title h2 {
	font-size: 36px;
	letter-spacing: -1px;
}
.features-content p {
	padding: 0;
}
.section-title {
	padding-left: 0;
	padding-right: 0;
}
.section-title h2 {
	font-size: 32px;
	letter-spacing: -1px;
}
.video-wrap img {
	width: 100%;
}
.video-position {
	bottom: 0;
}
.video-img a {
	height: 50px;
	width: 50px;
	line-height: 15px;
    padding: 17px;
}
.v-list ul li i {
	display: block;
	margin-right: 15px;
	float: left;
	margin-top: 2px;
}
.v-list ul li span {
	overflow: hidden;
	display: block;
}
.wp-list ul li {
	display: block;
	align-items: unset;
}
.wp-list {
	padding: 30px 20px;
	padding-bottom: 25px;
}
.wp-icon {
	margin-right: 0;
	margin-bottom: 10px;
}
.wp-img {
	margin-right: 0;
	margin-top: 30px;
	margin-bottom: -15px;
}
.wp-img > img {
	width: 100%;
}
.wp-img .wp-tag {
	left: 0;
    top: 75px;
    width: 25%;
}

.cta-content p {
	padding-right: 0;
}
.cta-right {
	margin-top: 35px;
}
.testimonial-area.pb-120 {
	padding-bottom: 185px;
	padding-top: 113px;
}
.blog-content {
	padding: 30px 20px;
}
.f-cta-area {
	padding: 35px 20px 0;
}

.menu-area {
	padding: 10px 0;
}
.responsive {
    float: right;
    display: block;
    cursor: pointer;
    margin-top: -38px;
}
.responsive i {
    color: #002e5b;
    font-size: 25px;
}
#mobile-menu {
display: none;
background: no-repeat;
padding: 20px 0;
margin-top: 21px;
background: #002e5b;
}
.main-menu {
	text-align: left !important;
}
.main-menu ul li {
	display: block;
	border-bottom: 1px solid #ffffff3d;
	margin-left: 0;
}
.button-group button{
	margin: 0 4px;
}
.main-menu  ul > li > a::before {content: none;}
.main-menu ul li a {
	color: #fff;
	padding: 12px 15px;
	display: inline-block;
	font-size: 14px;
	    font-family: 'Jost', sans-serif;
	font-weight: 500;
}
.second-menu {
	margin-bottom: 0;
	box-shadow: none;
	padding: 0;
	margin-top: 0;
}
.second-menu::before {
	content: none;
}
.second-header {
	padding: 10px 0;
}
.second-menu .main-menu ul li {
	margin-left: 0;
}
.s-about-img {
	margin-top: 50px;
}
.s-about-img img {
	width: 100%;
}
.s-video-content {
	padding-left: 15px;
}
.chosse-img {
	display: none;
}
.testimonial-active {
    width: 100%;
   
}
.testimonial-active .slick-arrow {
    width: 87%;
}
.portfolio .col3 .grid-item,.portfolio .col2 .grid-item {
    width:100%;
    padding: 15px;
}
.contact-img {
	display: none;
}
.contact-bg.pb-80 {
	padding-bottom: 120px;
}

.slider-shape {
	display: none !important;
}
.second-about {
	display: none;
}
.s-about-content.pl-30 {
	margin-top: 0;
}
.s-video-content h2 {
	font-size: 40px;
}
.features-shape {
	display: none;
}
.choose-wrap.pl-100 {
	padding-left: 0;
}
.choose-content p {
	padding-right: 0;
}
.choose-list ul li i {
	display: block;
	margin-right: 15px;
	float: left;
	margin-top: 2px;
}
.choose-list ul li span {
	overflow: hidden;
	display: block;
}
.inner-wp-icon {
	display: none;
}
.wp-thumb.mb-35 {
	margin-bottom: 25px;
}

.bsingle__content {
	padding: 25px;
}
.bsingle__content h2 {
	padding-right: 0;
	font-size: 24px;
}
.bsingle__content p {
	padding-right: 0px;
}
.quote-post .quote-icon {
	margin-top: 14px;
	float: unset;
	margin-bottom: 15px;
}
.pagination-wrap .pagination {
	text-align: center;
}
.widget__post-content h6 {
	font-size: 14px;
	padding-right: 0;
}
.widget__banner-overly > span {
	margin-bottom: 120px;
}
.widget__banner-overly h3 {
	color: #ffffff;
	font-size: 32px;
	margin-bottom: 100px;
	font-weight: 500;
}
.breadcrumb-title h2 {
	font-size: 25px;
}
.breadcrumb-title p {
	padding: 0;
	font-size: 15px;
}
.breadcrumb-area {
	min-height: 180px;
}
.details__content h2 {
	font-size: 24px;
}
.details__content blockquote {
	padding-right: 20px;
	padding-left: 45px;
}
.details__content figure img {
	float: unset;
	width: 100%;
	margin-right: 0;
	margin-bottom: 40px;
}
.post__tag ul li {
	margin-right: 2px;
}
.post__share {
	text-align: left !important;
}
.post__share h5 {
	margin-bottom: 15px;
}
.post__tag {
	margin-bottom: 35px;
}
.posts_navigation .prev-link {
	margin-bottom: 30px;
}
.posts_navigation .blog-filter {
	margin-bottom: 30px;
	display: inline-block;
}
.related-post-wrap .rp__content {
	padding: 35px;
	padding-left: 25px;
	padding-right: 25px;
}
.related-post-wrap .rp__content h3 {
	font-size: 20px;
}
.avatar__wrap {
	padding: 50px 20px;
}
.single__comment .comments-avatar {
	float: unset;
	margin-bottom: 15px;
}
.single__comment.children {
	margin-left: 0;
}
.comment__form {
	padding: 30px 20px;
}
.b-details-p.pt-120 {
	padding-top: 115px;
}
.menu-area .menu-tigger {display: none;}
.off-logo {display: block;}
.faq-img.text-right {
	text-align: center !important;
	margin-top: 30px;
}
.faq-area.pt-113.pb-120 {
	margin-top: 65px;
}
.s-slider-content p {
	padding-right: 0;
}
.slider-btn.mt-55 {
	margin-top: 25px;
}

.faq-img img {
	width: 100%;
}
.down-arrow {
	display: none;
}
.meta__info ul li {
	margin-right: 15px;
}
#scrollUp {
	right: 20px;
	bottom: 30px;
}
.video-wrap > img {
	display: none;
}
.video-img img {
	width: 100%;
}
.video-position {
	position: unset;
	bottom: unset;
	left: unset;
}
.counter-area.pb-90 {
	padding-bottom: 85px;
}
.cta-bg.pb-120 {
	padding-bottom: 115px;
}
.contact-wrapper input {
	height: 65px;
	padding: 0 25px;
}
.contact-wrapper textarea {
	height: 160px;
	padding: 30px 25px;
}
.second-slider-content {
	padding-top: 0;
}
.s-cta-p.pb-120 {
	padding-bottom: 120px;
}
}

/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .services-active2 .slider-nav .slick-list.draggable {
    width: 400px;
    float: right;
}
    .portfolio-active .slick-arrow {
    top: -173px;
    left: 60.5%;
}
    .portfolio-active .slick-next {
    left: 79.5%;
}
.slider-content h2 {
	font-size: 32px;
}
.features-content p {
	padding: 0 80px;
}
.section-title h2 {
	font-size: 42px;
	letter-spacing: -1px;
}
.wp-list {
	padding: 80px 50px;
	padding-bottom: 75px;
}
.blog-content h4 {
	font-size: 24px;
}
.f-insta ul li {
	width: 28%;
}
.f-insta ul li a img {
	width: 100%;
}
.s-video-content {
	padding-left: 40px;
	padding-right: 40px;
}
.s-video-content br{display: none;}
.choose-wrap.pl-100 {
	padding-left: 30px;
}
.inner-wp-icon {
	display: block;
}
.testimonial-p {
	padding-top: 114px;
}
.pricing-box {
	padding: 80px 40px;
}
.bsingle__content h2 {
	font-size: 26px;
}
.quote-post .quote-icon {
	float: left;
	margin-right: 30px;
	display: block;
	margin-bottom: 0;
	margin-top: 20px;
}
.widget__banner-overly > span {
	margin-bottom: 260px;
}
.widget__banner-overly h3 {
	margin-bottom: 210px;
	font-size: 60px;
}
.avatar__wrap {
	padding: 50px 40px;
}

}
/* Large Mobile :480px. */
@media only screen and (min-width: 600px) and (max-width: 767px) {
    .services-active2 .slider-nav .slick-list.draggable {
    width: 500px;
    float: right;
}
    .services-item {
    width: 470px;
}
    .button-group button {
    margin: 0 2px;

} }
