	/*
  	Flaticon icon font: Flaticon
  	Creation date: 28/06/2020 08:12
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-document:before { content: "\f100"; }
.flaticon-pie-chart:before { content: "\f101"; }
.flaticon-edition:before { content: "\f102"; }
.flaticon-research:before { content: "\f103"; }
.flaticon-graphic:before { content: "\f104"; }
.flaticon-dashboard:before { content: "\f105"; }
.flaticon-hand:before { content: "\f106"; }
.flaticon-index:before { content: "\f107"; }
.flaticon-agreement:before { content: "\f108"; }